<template>
  <header
    class="header"
    v-on="handleScroll"
    :class="{ scroll: isScroll }"
    :style="{
      backgroundImage: `url(${backgroundSrc})`,
    }"
  >
    <div class="header__container container">
      <a href="#main" @click="SET_ACTIVE_SECTION('main')">
        <logo-component class="header__logo" :modifier="'header'" />
      </a>
      <div
        class="header__burger"
        :class="{ active: isOpenMenu }"
        @click="toggleStateMenu"
      >
        <span></span>
      </div>
      <transition name="fade">
        <nav
          class="header__menu"
          v-if="isOpenMenu || getDevice === 'desktop'"
          :style="{
            backgroundImage: `url(${backgroundSrc})`,
          }"
        >
          <ul>
            <li
              v-for="(section, index) in sections"
              :key="index"
              :class="{ active: getActiveSection === section.name }"
              @click="SET_ACTIVE_SECTION(section.name)"
            >
              <a @click="toggleStateMenu" :href="`#${section.name}`">{{
                section.menuText
              }}</a>
            </li>
          </ul>
        </nav>
      </transition>
    </div>
    <div
      class="header__close-area"
      v-if="isOpenMenu"
      @click="toggleStateMenu"
    ></div>
  </header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import LogoComponent from "@/components/modules/Logo";
export default {
  name: "header-component",
  components: {
    LogoComponent,
  },
  data() {
    return {
      isOpenMenu: false,
      isScroll: false,
      limitPosition: 20,
      lastPosition: 0,
      sections: [
        {
          name: "main",
          menuText: "кто мы?",
        },
        {
          name: "services",
          menuText: "что мы делаем?",
        },
        {
          name: "technologies",
          menuText: "наш стек технологий",
        },
        {
          name: "prices",
          menuText: "наши услуги",
        },
        {
          name: "contacts",
          menuText: "контакты",
        },
      ],
    };
  },
  created() {
    if (this.getDevice === "desktop") {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeDestroyed() {
    if (this.getDevice === "desktop") {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
  computed: {
    backgroundSrc() {
      return require(`@/assets/images/background-${this.getDevice}.png`);
    },
    ...mapGetters(["getActiveSection", "getDevice"]),
  },
  methods: {
    toggleStateMenu() {
      if (this.getDevice === "mobile" || this.getDevice === "tablet") {
        this.isOpenMenu = !this.isOpenMenu;
      }
    },
    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.isScroll = true;
      }
      if (
        this.lastPosition > window.scrollY &&
        this.limitPosition > window.scrollY
      ) {
        this.isScroll = false;
      }
      this.lastPosition = window.scrollY;
    },
    ...mapMutations(["SET_ACTIVE_SECTION"]),
  },
};
</script>

<style lang="scss">
.header {
  position: fixed;
  background-color: $mainColor;
  width: 100%;
  height: 64px;
  z-index: 10;
  background-size: cover;
  &__close-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }
  &__container {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &__burger {
    position: absolute;
    width: 53px;
    height: 61px;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    & span {
      position: relative;
      display: block;
      width: 22px;
      height: 2px;
      background-color: $mainFontColor;
      border-radius: 2px;
      transition: all 0.4s ease;
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 22px;
        height: 2px;
        background-color: $mainFontColor;
        border-radius: 2px;
        transition: all 0.4s ease;
      }
      &::before {
        top: -9px;
      }
      &::after {
        bottom: -9px;
      }
    }
    &.active {
      & span {
        background-color: transparent;
        &::before,
        &::after {
          transition: all 0.4s ease;
          left: 0;
        }
        &::before {
          transform: rotate(45deg);
          top: 0;
        }
        &::after {
          transform: rotate(-45deg);
          bottom: 0;
        }
      }
    }
  }
  &__menu {
    background-color: $mainColor;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position-y: -64px;
    & ul {
      padding: 10px 16px 22px;
    }
    & li {
      color: $menuFontColor;
      &.active {
        color: $accentFontColor;
      }
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }
  @media (min-width: $tableWidth) {
    height: 89px;
    &__container {
      padding-top: 28px;
      padding-bottom: 28px;
    }
    &__burger {
      width: 62px;
    }
    &__menu {
      background-position-y: -89px;
    }
  }
  @media (min-width: $desktopWidth) {
    height: 114px;
    transition: all 0.3s ease;
    &.scroll {
      height: 88px;
      transition: all 0.3s ease;
      .header {
        &__container {
          padding-top: 26px;
          transition: all 0.3s ease;
        }
      }
    }
    &__close-area {
      display: none;
    }
    &__container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 47px;
      padding-bottom: 26px;
      transition: all 0.3s ease;
    }
    &__logo {
      & > svg {
        display: block;
        width: 307px;
        height: 41px;
      }
    }
    &__burger {
      display: none;
    }
    &__menu {
      position: static;
      width: 100%;
      margin-left: 30px;
      box-shadow: none;
      background: none !important;
      & ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
      }
      & li {
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:not(:last-child) {
          margin-bottom: 0;
        }
        &.active {
          color: $accentFontColor;
          &:hover {
            opacity: 1;
          }
        }
        &:hover {
          opacity: 0.7;
          transition: all 0.3s ease;
        }
      }
    }
  }
}
</style>
