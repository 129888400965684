<template>
  <svg-sprite />
  <header-component />
  <index-page />
  <footer-component />
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import HeaderComponent from "@/components/Header";
import IndexPage from "@/components/pages/Index";
import FooterComponent from "@/components/Footer";
import SvgSprite from "@/components/modules/SvgSprite";
export default {
  name: "App",
  components: {
    SvgSprite,
    HeaderComponent,
    IndexPage,
    FooterComponent,
  },
  created() {
    this.SET_DEVICE(document.querySelector("body").clientWidth);
  },
  mounted() {
    const resize = new ResizeObserver(this.handlerResize);
    resize.observe(document.querySelector("body"));
  },
  computed: {
    ...mapGetters(["getDevice"]),
  },
  methods: {
    handlerResize(entries) {
      for (let entry of entries) {
        this.SET_DEVICE(entry.target.clientWidth);
      }
    },
    ...mapMutations(["SET_DEVICE"]),
  },
};
</script>

<style lang="scss">
html {
  font-family: $fontInter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $mainFontSize;
  font-weight: $mainFontWeight;
  line-height: $mainLineHeight;
  color: $mainFontColor;
  text-align: start;
  height: 100%;
  @media screen and (min-width: $tableWidth) {
    font-size: 18px;
    line-height: 27px;
  }
  @media screen and (min-width: $desktopWidth) {
    font-size: 20px;
    line-height: 30px;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: $mainColor;
  max-width: 1280px;
  background-size: cover;
}

html,
body {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 64px;
  @media (min-width: $tableWidth) {
    scroll-padding-top: 88px;
  }
}

.container {
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (min-width: $tableWidth) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (min-width: $desktopWidth) {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1280px;
    margin: 0 auto;
  }
}

section {
  border-bottom: 1px solid $accentFontColor;
}

.light-accent {
  color: $lightFontColor;
}

.link {
  color: $accentFontColor;
  display: inline-block;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    height: 1px;
    width: 100%;
    background-color: $accentBackgroundColor;
  }
}

.fade-enter-active {
  animation: fade 0.3s ease-out both;
}

.fade-leave-active {
  animation: fade 0.3s reverse ease-in both;
}

.fade-f-enter-active {
  animation: fade 0.3s ease-out both;
  animation-delay: 0.3s;
}

.fade-f-leave-active {
  animation: fade 0.3s reverse ease-in both;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
