import { createStore } from "vuex";
import mutations from "./mutations";
import getters from "./getters";

const state = () => ({
  activeSection: "",
  device: "",
});

export default createStore({
  state,
  mutations,
  getters,
});
