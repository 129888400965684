<template>
  <main>
    <main-section id="main" />
    <services-section id="services" />
    <technologies-section id="technologies" />
    <prices-section id="prices" />
    <contacts-section id="contacts" />
  </main>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MainSection from "@/components/blocks/Main";
import ServicesSection from "@/components/blocks/Services";
import TechnologiesSection from "@/components/blocks/Technologies";
import PricesSection from "@/components/blocks/Prices";
import ContactsSection from "@/components/blocks/Contacts";

export default {
  name: "index-page",
  components: {
    MainSection,
    ServicesSection,
    TechnologiesSection,
    PricesSection,
    ContactsSection,
  },
  data() {
    return {
      options: {
        mobile: {
          main: {
            rootMargin: "0px 0px -150px",
            threshold: 1,
          },
          services: {
            rootMargin: "0px 0px -150px",
            threshold: 1,
          },
          technologies: {
            rootMargin: "0px 0px -150px",
            threshold: 1,
          },
          contacts: {
            rootMargin: "0px 0px -150px",
            threshold: 1,
          },
        },
        tablet: {
          main: {
            rootMargin: "0px 0px -50px",
            threshold: 1,
          },
          services: {
            rootMargin: "0px 0px -50px",
            threshold: 1,
          },
          technologies: {
            rootMargin: "0px 0px -50px",
            threshold: 1,
          },
          contacts: {
            rootMargin: "0px 0px -50px",
            threshold: 1,
          },
        },
        desktop: {
          main: {
            rootMargin: "0px 0px -50px",
            threshold: 1,
          },
          services: {
            rootMargin: "0px 0px -350px",
            threshold: 1,
          },
          technologies: {
            rootMargin: "100px 0px -500px",
            threshold: 1,
          },
          contacts: {
            rootMargin: "0px 0px -50px",
            threshold: 1,
          },
        },
      },
    };
  },
  mounted() {
    document.querySelector(
      "body"
    ).style.backgroundImage = `url(${this.backgroundSrc})`;
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      const observer = new IntersectionObserver(
        this.handleScroll,
        this.options[this.getDevice][section.id]
      );
      observer.observe(section);
    });
  },
  computed: {
    backgroundSrc() {
      return require(`@/assets/images/background-${this.getDevice}.png`);
    },
    ...mapGetters(["getDevice"]),
  },
  methods: {
    handleScroll(entries) {
      entries.forEach((item) => {
        if (item.isIntersecting !== false) {
          this.SET_ACTIVE_SECTION(item.target.id);
        }
      });
    },
    ...mapMutations(["SET_ACTIVE_SECTION"]),
  },
};
</script>
