<template>
  <section class="main">
    <div class="main__container container">
      <div class="main__background">
        <img :src="backgroundSrc" alt="girl-with-rings" />
      </div>
      <p>
        Мы создаём <span class="light-accent">цифровые решения</span>, лежащие в
        основе крупнейшего омниканального супермаркета украшений.
      </p>
      <p>
        Наша команда состоит из экспертов, постоянно находящих технические
        решения на вызовы бизнеса.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "main-section",
  computed: {
    backgroundSrc() {
      return require(`../../assets/images/main-${this.getDevice}.png`);
    },
    ...mapGetters(["getDevice"]),
  },
};
</script>

<style lang="scss">
.main {
  padding-top: 64px;
  &__container {
    padding-top: 21px;
    padding-bottom: 50px;
  }
  &__background {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    mix-blend-mode: luminosity;
  }
  & p {
    max-width: 325px;
  }
  @media (min-width: $tableWidth) {
    padding-top: 89px;
    &__container {
      padding-bottom: 31px;
      position: relative;
    }
    &__background {
      position: absolute;
      top: 10px;
      right: 17px;
      z-index: -1;
    }
    & p {
      font-size: 20px;
      line-height: 30px;
      max-width: 362px;
      & + p {
        margin-top: 12px;
      }
    }
  }
  @media (min-width: $desktopWidth) {
    padding-top: 114px;
    &__container {
      display: grid;
      padding-top: 86px;
      padding-bottom: 48px;
    }
    &__background {
      top: 0;
      right: 110px;
    }
    & p {
      font-size: 40px;
      line-height: 60px;
      max-width: 513px;
      & + p {
        font-size: 28px;
        line-height: 42px;
        max-width: 590px;
        margin-top: 15px;
        justify-self: end;
      }
    }
  }
}
</style>
