import { checkDevice } from "@/assets/js/checkDevice";

export default {
  SET_ACTIVE_SECTION(state, section) {
    state.activeSection = section;
  },
  SET_DEVICE(state, body) {
    state.device = checkDevice(body);
  },
};
