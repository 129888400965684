export function checkDevice(width) {
  console.log(width);
  const isMobile = width < 744;
  const isTablet = width < 1280 && width >= 744;
  const isDesktop = width >= 1280;
  if (isMobile) {
    return "mobile";
  }
  if (isTablet) {
    return "tablet";
  }
  if (isDesktop) {
    return "desktop";
  }
  return "";
}
