<template>
  <section class="prices">
    <div class="prices__container container">
      <h2 class="prices__title title">наши услуги</h2>
      <ul class="prices__list">
        <li class="prices__item" v-for="item in items" :key="item.id">
          <span class="prices__subject">{{ item.subject }}</span>
          <span class="prices__cost">{{ item.price }}</span>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: `prices-section`,
  data() {
    return {
      items: [
        {
          id: 1,
          subject: `Backend разработка`,
          price: `3 000 руб/час`,
        },
        {
          id: 2,
          subject: `Frontend разработка`,
          price: `2 500 руб/час`,
        },
        {
          id: 3,
          subject: `Mobile разработка`,
          price: `3 500 руб/час`,
        },
        {
          id: 4,
          subject: `Администрирование DWH и DB`,
          price: `2 500 руб/час`,
        },
        {
          id: 5,
          subject: `QA`,
          price: `2 500 руб/час`,
        },
        {
          id: 6,
          subject: `DevOps и системное администрирование`,
          price: `4 000 руб/час`,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.prices {
  &__container {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  &__list {
    margin-top: 24px;
  }
  &__item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    &::before {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      min-width: 8px;
      background-color: $accentFontColor;
      margin-right: 14px;
    }
  }
  &__subject {
    flex: auto;
  }
  &__cost {
    flex: none;
  }
  @media (min-width: $desktopWidth) {
    &__title {
      text-align: center;
    }
    &__list {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
