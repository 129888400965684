<template>
  <footer
    class="footer"
    :style="{
      backgroundImage: `${linearGradient}, url(${backgroundSrc})`,
    }"
  >
    <div class="footer__container container">
      <div class="footer__background">
        <a href="#main" @click="SET_ACTIVE_SECTION('main')">
          <logo-component class="footer__logo" :modifier="'footer'" />
        </a>
      </div>
    </div>
    <div class="footer__links">
      <a v-for="(link, index) in links" :key="index" :href="link.href">
        <inline-svg :id="link.id" :width="link.width" :height="link.height" />
      </a>
    </div>
  </footer>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import LogoComponent from "@/components/modules/Logo";
import InlineSvg from "@/components/modules/InlineSvg";

export default {
  name: "footer-component",
  components: {
    LogoComponent,
    InlineSvg,
  },
  data() {
    return {
      linearGradient:
        "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
      links: [
        {
          id: "#go-to-poisondrop",
          href: "https://poisondrop.ru",
          width: 101,
          height: 39,
        },
        {
          id: "#go-to-app-store",
          href: "https://apps.apple.com/us/app/poison-drop/id1582987280",
          width: 111,
          height: 39,
        },
        {
          id: "#go-to-google-play",
          href: "https://play.google.com/store/apps/details?id=ru.poisondrop",
          width: 119,
          height: 39,
        },
      ],
    };
  },
  computed: {
    backgroundSrc() {
      return require(`@/assets/images/footer-${this.getDevice}.jpg`);
    },
    ...mapGetters(["getActiveSection", "getDevice"]),
  },
  methods: {
    ...mapMutations(["SET_ACTIVE_SECTION"]),
  },
};
</script>

<style lang="scss">
.footer {
  background-repeat: no-repeat;
  background-size: cover;
  &__container {
    padding-top: 84px;
    padding-bottom: 83px;
  }
  &__background {
    display: flex;
    justify-content: center;
    align-self: center;
  }
  &__links {
    background-color: $accentBackgroundColor;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & a {
      line-height: 0;
    }
  }
  @media (min-width: $tableWidth) {
    &__container {
      padding-top: 154px;
      padding-bottom: 154px;
    }
    &__logo {
      & > svg {
        display: block;
        width: 433px;
        height: 67px;
      }
    }
    &__links {
      justify-content: center;
      column-gap: 30px;
    }
  }
  @media (min-width: $desktopWidth) {
    &__container {
      padding-top: 205px;
      padding-bottom: 205px;
    }
    &__logo {
      & > svg {
        display: block;
        width: 745px;
        height: 126px;
      }
    }
    &__links {
      & a {
        transition: all 0.3s ease;
        &:hover {
          transition: all 0.3s ease;
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
