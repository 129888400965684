<template>
  <section class="contacts">
    <div class="contacts__container container">
      <div class="contacts__background">
        <img :src="backgroundSrc" alt="girl-with-chains" />
      </div>
      <h2 class="contacts__title title">контакты</h2>
      <div class="contacts__content">
        <div class="contacts__email">
          <h3>написать нам на почту</h3>
          <a class="link" href="mailto:it@poisondrop.ru">it@poisondrop.ru</a>
        </div>
        <div class="contacts__career">
          <h3>о карьере в нашей компании</h3>
          <a class="link" href="https://poisondrop.ru/career/">
            https://poisondrop.ru/career/
          </a>
        </div>
        <div class="contacts__email">
          <h3>ООО "Пойзон Дроп Тех"</h3>
          <p><span class="light-accent">ИНН: </span>9726032295</p>
          <p><span class="light-accent">КПП: </span>772601001</p>
          <p><span class="light-accent">ОГРН: </span>1227700892979</p>
          <p>
            <span class="light-accent">Юридический адрес: </span>115191,
            г.Москва, Холодильный пер., д. 3, эт. 1, пом. 1.
          </p>
        </div>
        <div class="contacts__email">
          <h3>Банковские реквизиты</h3>
          <p>
            <span class="light-accent">Расчетный счет: </span>
            40702810502860018081
          </p>
          <p><span class="light-accent">Банк: </span>АО "АЛЬФА-БАНК"</p>
          <p><span class="light-accent">БИК: </span>044525593</p>
          <p>
            <span class="light-accent">Корреспондентский счет: </span>
            30101810200000000593
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "contacts-section",
  computed: {
    backgroundSrc() {
      return require(`../../assets/images/contacts-${this.getDevice}.png`);
    },
    ...mapGetters(["getDevice"]),
  },
};
</script>

<style lang="scss">
.contacts {
  position: relative;
  &__container {
    padding-top: 20px;
    padding-bottom: 33px;
  }
  &__content {
    margin-top: 20px;
    & h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    & div + div {
      margin-top: 20px;
    }
  }
  &__background {
    position: absolute;
    right: 0;
    top: 38px;
    &::before {
      position: absolute;
      content: "";
      display: block;
      width: 251px;
      height: 255px;
      z-index: -1;
      top: -16px;
      right: 0;
      background-color: $photoBackgroundColor;
      border: 1px solid $accentBackgroundColor;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transform: translateX(52%) matrix(-1, 0, 0, 1, 0, 0);
    }
  }
  @media (min-width: $tableWidth) {
    &__container {
      padding-top: 36px;
      padding-bottom: 36px;
    }
    &__content {
      max-width: 489px;
      & h3 {
        font-size: 18px;
        line-height: 27px;
      }
      & div + div {
        margin-top: 18px;
      }
    }
    &__background {
      top: 62px;
      &::before {
        width: 439px;
        height: 439px;
        top: -22px;
        transform: translateX(48%) matrix(-1, 0, 0, 1, 0, 0);
      }
    }
  }
  @media (min-width: $desktopWidth) {
    &__container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &__content {
      margin-top: 35px;
      max-width: 800px;
      & h3 {
        font-size: 20px;
        line-height: 30px;
      }
      & div + div {
        margin-top: 20px;
      }
      & a {
        transition: all 0.3s ease;
        &:hover {
          opacity: 0.7;
          transition: all 0.3s ease;
        }
      }
    }
    &__background {
      top: 80px;
      &::before {
        width: 575px;
        height: 575px;
        top: -30px;
        transform: translateX(28%) matrix(-1, 0, 0, 1, 0, 0);
      }
    }
  }
}
</style>
