<template>
  <section class="services">
    <div class="services__container container">
      <h2 class="services__title title">что мы делаем?</h2>
      <ul class="services__list">
        <li v-for="(service, index) in services[getDevice]" :key="index">
          {{ service }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "services-section",
  data() {
    return {
      services: {
        mobile: [
          "развиваем многофункциональный маркетплейс для дизайнеров украшений",
          "разрабатываем цифровые витрины: web, iOS, Android",
          "создаём рекомендательную систему для поиска дизайнерских украшений",
          "поддерживаем динамически развивающуюся сеть офлайн магазинов",
          "внедряем аналитические системы для обеспечения Data-Driven Development",
          "проектируем IT-инфраструктуру с прицелом на масштабируемость, надёжность, безопасность и бесперебойность",
        ],
        tablet: [
          "развиваем многофункциональный маркетплейс для дизайнеров украшений",
          "поддерживаем динамически развивающуюся сеть офлайн магазинов",
          "разрабатываем цифровые витрины: web, iOS, Android",
          "внедряем аналитические системы для обеспечения Data-Driven Development",
          "создаём рекомендательную систему для поиска дизайнерских украшений",
          "проектируем IT-инфраструктуру с прицелом на масштабируемость, надёжность, безопасность и бесперебойность",
        ],
        desktop: [
          "развиваем многофункциональный маркетплейс для дизайнеров украшений",
          "создаём рекомендательную систему для поиска дизайнерских украшений",
          "поддерживаем динамически развивающуюся сеть офлайн магазинов",
          "разрабатываем цифровые витрины: web, iOS, Android",
          "внедряем аналитические системы для обеспечения Data-Driven Development",
          "проектируем IT-инфраструктуру с прицелом на масштабируемость, надёжность, безопасность и бесперебойность",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getDevice"]),
  },
};
</script>

<style lang="scss">
.services {
  &__container {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  &__list {
    margin-top: 24px;
    & li {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        min-width: 8px;
        background-color: $listFontColor;
        margin-right: 14px;
      }
    }
  }
  @media (min-width: $tableWidth) {
    &__container {
      padding-bottom: 32px;
    }
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 15px;
      & li {
        max-width: 342px;
        align-self: start;
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
  @media (min-width: $desktopWidth) {
    &__container {
      padding-top: 40px;
      padding-bottom: 35px;
    }
    &__list {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 32px;
      & li {
        max-width: 379px;
      }
    }
  }
}
</style>
