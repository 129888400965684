<template>
  <svg :width="width" :height="height">
    <use :href="id"></use>
  </svg>
</template>

<script>
export default {
  name: `InlineSvg`,
  props: {
    id: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
  },
};
</script>
