<template>
  <section class="technologies">
    <div class="technologies__container container">
      <h2 class="technologies__title title">наш стек технологий</h2>
      <ul class="technologies__filters">
        <li
          class="technologies__filters-button"
          v-for="item in filters"
          :key="item.filter"
          :class="{
            'technologies__filters-button_active':
              item.filter === currentFilter,
          }"
          @click="currentFilter = item.filter"
        >
          {{ item.name }}
        </li>
      </ul>
      <ul class="technologies__list">
        <li
          class="technologies__item"
          v-for="item in filteredTechnologies"
          :key="item.name"
        >
          <inline-svg :id="`#${item.name}`" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import InlineSvg from "@/components/modules/InlineSvg";

export default {
  name: `technologies-section`,
  components: {
    InlineSvg,
  },
  data() {
    return {
      currentFilter: "all",
      filters: [
        {
          name: `Все`,
          filter: `all`,
        },
        {
          name: `Backend`,
          filter: `backend`,
        },
        {
          name: `Frontend`,
          filter: `frontend`,
        },
        {
          name: `Mobile`,
          filter: `mobile`,
        },
        {
          name: `DWH и DB`,
          filter: `data`,
        },
        {
          name: `QA`,
          filter: `qa`,
        },
        {
          name: `DevOps и Системное администрирование`,
          filter: `devops`,
        },
      ],
      technologies: [
        {
          name: `php`,
          filters: [`all`, `backend`],
        },
        {
          name: `go`,
          filters: [`all`, `backend`],
        },
        {
          name: `python`,
          filters: [`all`, `backend`],
        },
        {
          name: `laravel`,
          filters: [`all`, `backend`],
        },
        {
          name: `symphony`,
          filters: [`all`, `backend`],
        },
        {
          name: `js`,
          filters: [`all`, `frontend`],
        },
        {
          name: `react`,
          filters: [`all`, `frontend`],
        },
        {
          name: `vue-js`,
          filters: [`all`, `frontend`],
        },
        {
          name: `nuxt-js`,
          filters: [`all`, `frontend`],
        },
        {
          name: `next-js`,
          filters: [`all`, `frontend`],
        },
        {
          name: `react-native`,
          filters: [`all`, `mobile`],
        },
        {
          name: `my-sql`,
          filters: [`all`, `data`],
        },
        {
          name: `maria-db`,
          filters: [`all`, `data`],
        },
        {
          name: `postgres-sql`,
          filters: [`all`, `data`],
        },
        {
          name: `click-house`,
          filters: [`all`, `data`],
        },
        {
          name: `test-rail`,
          filters: [`all`, `qa`],
        },
        {
          name: `selenium`,
          filters: [`all`, `qa`],
        },
        {
          name: `cypress`,
          filters: [`all`, `qa`],
        },
        {
          name: `git-lab`,
          filters: [`all`, `devops`],
        },
        {
          name: `git-hub`,
          filters: [`all`, `devops`],
        },
        {
          name: `docker`,
          filters: [`all`, `devops`],
        },
        {
          name: `bitbucket`,
          filters: [`all`, `devops`],
        },
        {
          name: `jenkins`,
          filters: [`all`, `devops`],
        },
        {
          name: `kubernetes`,
          filters: [`all`, `devops`],
        },
        {
          name: `prometheus`,
          filters: [`all`, `devops`],
        },
        {
          name: `grafana`,
          filters: [`all`, `devops`],
        },
        {
          name: `elk`,
          filters: [`all`, `devops`],
        },
      ],
    };
  },
  computed: {
    filteredTechnologies() {
      return this.technologies.filter((item) =>
        item.filters.includes(this.currentFilter)
      );
    },
  },
};
</script>

<style lang="scss">
.technologies {
  &__container {
    padding-top: 24px;
    padding-bottom: 40px;
  }
  &__filters {
    margin-top: 34px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 18px;
  }
  &__filters-button {
    padding: 6px 10px;
    border: 1px solid $mainFontColor;
    border-radius: 100px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &_active {
      color: $mainColor;
      background: $accentBackgroundColor;
      border: 1px solid $accentBackgroundColor;
    }
  }
  &__list {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 12px;
    column-gap: 12px;
  }
  &__item {
    position: relative;
    width: 100%;
    padding-top: 100%;
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: $phoneWidth) {
    &__list {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media (min-width: $smTableWidth) {
    &__list {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  @media (min-width: $tableWidth) {
    &__container {
      padding-top: 30px;
    }
    &__list {
      margin-top: 36px;
      grid-template-columns: repeat(7, 1fr);
      row-gap: 16px;
      column-gap: 18px;
    }
  }
  @media (min-width: $smDesktopWidth) {
    &__list {
      grid-template-columns: repeat(9, 1fr);
    }
  }
  @media (min-width: $desktopWidth) {
    &__container {
      padding-top: 40px;
    }
    &__list {
      margin-top: 50px;
      grid-template-columns: repeat(10, 1fr);
      row-gap: 20px;
      column-gap: 22px;
    }
  }
}
</style>
